<template>
  <div id="logo" style="padding: 5%; height: 50ex; position:relative">
    <svg viewBox="0 0 400 80">
      <defs id="Svglogo"></defs>
      <g id="SvgjsG1676" class="logo"
        transform="matrix(0.8865504081126907,0,0,0.8865504081126907,-12.065064686629364,-9.069410269162256)">
        <g xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M80.11,49.695c0.003,5.951,1.481,10.771,3.303,10.771   c1.823,0,3.302-4.824,3.305-10.768c-0.003-5.951-1.479-10.773-3.303-10.773C81.591,38.926,80.107,43.748,80.11,49.695z">
          </path>
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M39.138,14.154c-15.075,6.26-25.529,19.93-25.529,35.779   c-0.004,15.852,10.454,29.52,25.536,35.777C30.504,78.232,24.764,65,24.764,49.934S30.508,21.637,39.138,14.154z">
          </path>
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M49.349,10.23c-7.066,0-12.803,17.854-12.803,39.857   c0,22.016,5.736,39.859,12.808,39.861c3.491-0.002,6.66-4.367,8.969-11.438c1.954,4.756,4.414,7.598,7.084,7.598   c6.367,0.002,11.532-16.066,11.527-35.883c0.005-19.816-5.156-35.887-11.521-35.879c-2.651,0-5.093,2.797-7.041,7.484   C56.057,14.668,52.871,10.234,49.349,10.23z M75.225,50.227c0.005,11.121-2.764,20.143-6.174,20.143   c-3.412,0-6.176-9.023-6.176-20.139c0-11.127,2.764-20.146,6.176-20.15C72.465,30.084,75.229,39.102,75.225,50.227z M57.792,23.318   c-2.395,6.576-3.907,16.191-3.907,26.908c-0.001,10.623,1.484,20.166,3.841,26.736c-1.15,1.559-2.418,2.424-3.748,2.424   c-5.202-0.004-9.414-13.117-9.414-29.299c0-16.174,4.212-29.293,9.407-29.293C55.333,20.797,56.626,21.701,57.792,23.318z">
          </path>
        </g>
      </g>
      <g transform="matrix(1,0,0,1,405,7)" fill="#ffffff">
        <rect xmlns="http://www.w3.org/2000/svg" y="0" height="1" width="1" opacity="0"></rect>
        <rect xmlns="http://www.w3.org/2000/svg" y="0" x="-320" width="3" height="58"></rect>
      </g>
      <g transform="matrix(2.322733246624474,0,0,2.322733246624474,102.11736200036395,3.975593904824713)" fill="#ffffff">
        <path
          d="M3.66 7.76 l3 0 l0 7.24 c0 3.14 -2.44 5.1 -5.4 5.1 c-0.22 0 -0.4 0 -0.5 -0.02 s-0.24 -0.06 -0.38 -0.08 l0 -2.78 c0.26 0.06 0.54 0.1 0.8 0.1 c1.54 0 2.48 -0.72 2.48 -2.32 l0 -7.24 z M16.36 17.52 l-4.5 0 l-0.88 2.48 l-3.26 0 l4.9 -12.24 l3 0 l4.9 12.24 l-3.28 0 z M12.84 14.74 l2.54 0 l-1.26 -3.56 z M26.040000000000003 20 l-4.3 0 l0 -12.24 l4.3 0 c3.74 0 6.3 2.44 6.3 6.12 c0 3.78 -2.7 6.12 -6.3 6.12 z M24.740000000000002 10.54 l0 6.68 l1.3 0 c2.12 0 3.3 -1.34 3.3 -3.34 c0 -0.46 -0.06 -0.88 -0.18 -1.3 c-0.52 -1.46 -1.66 -2.04 -3.12 -2.04 l-1.3 0 z M34.2 7.76 l9.1 0 l0 2.78 l-6.1 0 l0 1.96 l4.9 0 l0 2.78 l-4.9 0 l0 1.94 l6.1 0 l0 2.78 l-9.1 0 l0 -12.24 z M49.3 20 l-4.3 0 l0 -12.24 l4.3 0 c3.74 0 6.3 2.44 6.3 6.12 c0 3.78 -2.7 6.12 -6.3 6.12 z M48 10.54 l0 6.68 l1.3 0 c2.12 0 3.3 -1.34 3.3 -3.34 c0 -0.46 -0.06 -0.88 -0.18 -1.3 c-0.52 -1.46 -1.66 -2.04 -3.12 -2.04 l-1.3 0 z M74.62 7.76 l2.92 0 l-2.26 12.24 l-4.44 0 l-1.6 -7.14 l-1.7 7.14 l-4.68 0 l-1.98 -12.24 l3.06 0 l1.38 9.16 l1.8 -7.14 l4.26 0 l1.72 7.14 z M86.42 17.52 l-4.5 0 l-0.88 2.48 l-3.26 0 l4.9 -12.24 l3 0 l4.9 12.24 l-3.28 0 z M82.9 14.74 l2.54 0 l-1.26 -3.56 z M89.67999999999999 7.76 l10.2 0 l0 2.78 l-3.6 0 l0 9.46 l-3 0 l0 -9.46 l-3.6 0 l0 -2.78 z M101.44 7.76 l9.1 0 l0 2.78 l-6.1 0 l0 1.96 l4.9 0 l0 2.78 l-4.9 0 l0 1.94 l6.1 0 l0 2.78 l-9.1 0 l0 -12.24 z M115.24 10.58 l0 2.92 l2.8 0 c0.92 0 1.46 -0.54 1.46 -1.46 s-0.54 -1.46 -1.46 -1.46 l-2.8 0 z M119.78 15.48 c0.56 0.76 1.12 1.52 1.7 2.26 c0.56 0.74 1.12 1.5 1.68 2.26 l-3.66 0 c-0.72 -0.96 -1.42 -1.92 -2.12 -2.88 c-0.7 -0.94 -1.42 -1.9 -2.14 -2.86 l0 5.74 l-3 0 l0 -12.24 l5.8 0 c2.28 0 4.22 1.74 4.22 4.04 c0 1.62 -1 3.04 -2.48 3.68 z">
        </path>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld-2',
  props: {
    msg: String
  }
}
</script>

<style scoped>
#logo {
  min-height: 100ex;
  background-image: url('./../assets/about.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
}

div svg {
  position: absolute;
  top: 45%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 10;
  margin: 20px;
  text-align: center;
}

div svg .logo {
  fill: #f64c72;
}
</style>